import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

const Page1=()=>{

    const Telegram = window.Telegram.WebApp;

    const navigate = useNavigate();

    const [username, setUsername] = useState('')

    const findUser=()=>{

        

    }

    useEffect(()=>{

        if (Telegram) {

            Telegram.ready();

            Telegram.MainButton.setText("Play Again");

            Telegram.MainButton.show();
        }

    },[])

    return(
        <>
        <h1>Page 1</h1>
        <input type="text" onChange={(e)=>{
            setUsername(e.target.value);
            alert(e.target.value)
        }} />
        <button onClick={()=>{
            navigate({
                pathname: "/PlayDICE",
            })
        }}>Go to</button>
        <br />
        <a onClick={()=>{
            window.location.replace("/PlayDICE")
        }}>Go back</a>

        </>    
    )
}

export default Page1;