import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import PlayWOF from './pages/PlayWOF';
import PlayDICE from './pages/PlayDICE';
import PlayingWOF from './pages/PlayingWOF';
import PlayingDICE from './pages/PlayingDICE';
import Wallets from './pages/Wallets';
import reportWebVitals from './reportWebVitals';
import GamesHistory from './pages/GameHistory';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<div>Hello world!</div>} />
        <Route path="/page1" element={<Page1 />} />
        <Route path="/page2" element={<Page2 />} />
        <Route path="/wallets" element={<Wallets />} />
        <Route path="/games" element={<GamesHistory />} />
        <Route path="/PlayWOF" element={<PlayWOF />} />
        <Route path="/PlayingWOF" element={<PlayingWOF />} />
        <Route path="/PlayDICE" element={<PlayDICE />} />
        <Route path="/PlayingDICE" element={<PlayingDICE />} />
      </Routes>
    </BrowserRouter>
  </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
