import React from "react";
import { useNavigate } from "react-router-dom";

const Page2=()=>{

    const navigate = useNavigate();

    return(
        <>
        <h1>Page 2</h1>
        <button onClick={()=>{
            navigate({
                pathname: "/page1"
            })
        }}>Go to</button>
        </>
    )
}

export default Page2;