import { useEffect, useState } from "react";
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { config } from "../config";
import wof from '../assets/wof.jpeg'
import './WOF.css';

const PlayingWOF = () => {

    const Telegram = window.Telegram.WebApp;

    const navigate = useNavigate();

    const [isGameOver, setIsGameOver] = useState(false);

    const [showPlayButton, setShowPlayButton] = useState(false);

    useEffect(() => {

        const sectors = [
            { color: '#f82', label: '1' },
            { color: '#0bf', label: '2' },
            { color: '#fb0', label: '3' },
            { color: '#0fb', label: '4' },
            { color: '#b0f', label: '5' },
            { color: '#f0b', label: '6' },
            { color: '#bf0', label: '7' },
            { color: '#f0b', label: '8' },
            { color: '#bf0', label: '9' },
            { color: '#0fb', label: '10' },
        ]

        const urlParams = new URLSearchParams(window.location.search);

        // To get the value of a specific parameter
        const userChoice = urlParams.get('choice'); // "value"
        console.log(userChoice)

        document.getElementById("choice1").textContent = userChoice[1];

        document.getElementById("choice2").textContent = userChoice[3];

        const game_id = urlParams.get('game_id');

        const authentic = localStorage.getItem(`${game_id}`);

        console.log(authentic)

        /*if (authentic == null) {
            navigate({
                pathname: "/PlayWOF",
            })
        }

        if (game_id == "" || game_id == null) {
            navigate({
                pathname: "/PlayWOF",
            })
        }*/

        const wof = document.querySelector('#wheelOfFortune');
        const ctx = document.querySelector('#wheel').getContext('2d');
        const spinEl = document.querySelector('#spin');
        const rad = ctx.canvas.width / 2;
        const PI = Math.PI;
        const TAU = 2 * PI;
        const arc = TAU / sectors.length;
        let angVel = 0;
        let ang = 0;
        let hasLogged = false;
        const spinDuration = 10000; // 10 seconds in milliseconds
        let spinStartTime;

        function getIndex() {
            return Math.floor(sectors.length - (ang / TAU) * sectors.length) % sectors.length;
        }

        function drawSector(sector, i) {
            const ang = arc * i;
            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = sector.color;
            ctx.moveTo(rad, rad);
            ctx.arc(rad, rad, rad, ang, ang + arc);
            ctx.lineTo(rad, rad);
            ctx.fill();

            ctx.translate(rad, rad);
            ctx.rotate(ang + arc / 2);
            ctx.textAlign = 'right';
            ctx.fillStyle = '#fff';
            ctx.font = 'bold 30px sans-serif';
            ctx.fillText(sector.label, rad - 10, 10);

            ctx.restore();
        }

        function rotate() {
            const sector = sectors[getIndex()];
            ctx.canvas.style.transform = `rotate(${ang - PI / 2}rad)`;
            spinEl.textContent = sector.label
            spinEl.style.background = sector.color

            if (angVel === 0 && !hasLogged && Date.now() - spinStartTime >= spinDuration) {

                var newDiv = document.createElement('div');
                newDiv.id = 'reloadButton';
                newDiv.innerHTML = sector.label;
                newDiv.style.background = sector.color
                wof.appendChild(newDiv);

                const result = sector.label;

                document.getElementById('result').textContent = `The wheel stopped on: ${result}.`;
                if (userChoice.includes(result)) {
                    document.getElementById('result').textContent += `Congratulations, You've won! <br />Your reward will be sent shortly`;
                } else {
                    document.getElementById('result').textContent += ` You lost. Try again`;
                }
                uploadResult(game_id, config.USER_ID, result);
                setIsGameOver(true);
                hasLogged = true;
            }
        }

        function frame() {
            const elapsed = Date.now() - spinStartTime;
            if (elapsed < spinDuration) {
                const remainingTimeRatio = (spinDuration - elapsed) / spinDuration;
                angVel *= remainingTimeRatio * 0.09 + 0.91;  // This formula is set to reduce the velocity over time.
            } else {
                angVel = 0;
            }

            ang += angVel;
            ang %= TAU;
            rotate();
            requestAnimationFrame(frame);
        }

        function init() {
            sectors.forEach(drawSector);
            frame();
        }

        function spinWheel() {
            localStorage.removeItem(`${game_id}`);
            if (!angVel) {
                angVel = Math.random() * 0.1 + 0.3;  // Random velocity
                spinStartTime = Date.now();
                hasLogged = false;
            }
        }

        init();

        if (Telegram) {

            Telegram.ready();

            Telegram.MainButton.onClick(() => {

                spinWheel();

                Telegram.MainButton.offClick(()=>{
                    console.log("click event removed!")
                });

                Telegram.MainButton.hide();

            })

            Telegram.MainButton.setText("Spin the Wheel!");

            Telegram.MainButton.show();
        }

        const uploadResult = async (game_id, user_id, result) => {

            //const request = await fetch(`${config.API_URL}gameOver?game_id=${game_id}&user_id=${user_id}&result=${result}`);

            //const response = await request.json();

            //console.log(response);

        }

    }, [])

    useEffect(() => {

        if (isGameOver) {

            Telegram.MainButton.onClick(() => {

                window.location.replace("/PlayWOF");

            })

            Telegram.MainButton.setText("Play Again");

            Telegram.MainButton.show();
        }

    }, [isGameOver])

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        <Card.Body>
                            <center>
                                <h4>Playing Wheel of Fortune</h4>
                            </center>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col">

                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        <Card.Body>
                            <center>
                                <div style={{ margin: 10, marginBottom: 30 }}>
                                    <Row>
                                        <Col>First Choice: <span id="choice1"></span></Col>
                                        <Col>Second Choice: <span id="choice2"></span></Col>
                                    </Row>
                                </div>
                            </center>
                            <center>
                                <div id="wheelOfFortune">
                                    <canvas id="wheel" width="300" height="300"></canvas>
                                    <div id="spin">SPIN</div>
                                </div>
                            </center>
                            <p></p>
                            <center>
                                <div id="result"></div>
                            </center>
                            <p></p>
                            <div>
                            </div>

                        </Card.Body>
                    </Card>

                </div>
            </div>

        </div>
    )
}

export default PlayingWOF;