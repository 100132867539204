import { useState, useEffect } from 'react';
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, createSearchParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import dice from '../assets/dice.png'
import { config } from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { setLudusAmount, setChoice1, setChoice2, selectLudusAmount, selectChoice1, selectChoice2, resetGame } from './diceSlice';


const PlayDICE = () => {

    const Telegram = window.Telegram.WebApp;

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const choice1 = useSelector(selectChoice1);
    const choice2 = useSelector(selectChoice2);
    const ludusAmount = useSelector(selectLudusAmount);


    const playDICE = async () => {

        //alert(ludusAmount + "<->" + choice1 + "<->" + choice2);

        if (ludusAmount == "" || isNaN(ludusAmount) || ludusAmount == null || choice1 == "" || isNaN(choice1) || choice1 == null || choice2 == "" || isNaN(choice2) || choice2 == null) {

            showNotify("error", "Invalid entry", true);

            return;

        }

        //setIsLoading(true);

        const user_id = config.USER_ID;

        dispatch(setLudusAmount(""));
        dispatch(setChoice1(""));
        dispatch(setChoice2(""));
        navigate({
            pathname: "/PlayingDICE",
            search: `?${createSearchParams({ choice: `[${choice1},${choice2}]`, game_id: '114578', started: false })}`,
        });
        return

        try {

            const request = await fetch(`${config.API_URL}playDICE?amount=${ludusAmount}&user_id=${user_id}&choice1=${choice1}&choice2=${choice2}`);

            const response = await request.json();

            if (Object.keys(response).length > 0) {

                if (response.payment_status == "payment_started") {

                    setLudusAmount(null);

                    setChoice1(null);

                    setChoice2(null)

                    Telegram.MainButton.hide();

                    showNotify("success", "Payment is processing, Game will be loaded automatically", false);

                    checkPayment(response.game_id)

                } else if (response.payment_status == "insufficient_error") {

                    showNotify("error", "Insufficient eth or ludus balance, check your wallet!", false)

                }
                else if (response.payment_status == "insufficient_eth") {

                    showNotify("error", "Insufficient eth, check your wallet!", false)

                }

            } else {

                showNotify("error", "An unknown error occurred", false);

            }

        } catch (e) {

            console.log(e)

            showNotify("error", "An unknown error occurred", false);

        }

    }

    useEffect(() => {

        if (Telegram) {

            Telegram.ready();

            Telegram.MainButton.setText("Continue");

            Telegram.MainButton.show();

            Telegram.MainButton.onClick(() => {

                if (ludusAmount != "" && ludusAmount != null && choice1 != "" && choice1 != null && choice2 != "" && choice2 != null) {

                    playDICE();

                }else if(ludusAmount == "" && ludusAmount == null && choice1 == "" && choice1 == null && choice2 == "" && choice2 == null) {
                    alert("Invalid entry")
                }

            })
        }

        return ()=>{
            setLudusAmount("");
            setChoice1("");
            setChoice2("");
        }

    }, [ludusAmount, choice1, choice2])

    useEffect(() => {
        return () => {
            dispatch(resetGame());
        };
    },[])

    const showNotify = (type, message, autoClose) => {
        if (type == "error") {
            return toast.error(`${message}`, {
                position: "top-center",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
            return toast.success(`${message}`, {
                position: "top-center",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    let checkingPayment = false;

    const checkPayment = async (game_id) => {

        if (checkingPayment == false) {

            checkingPayment = true;

            const request = await fetch(`${config.API_URL}checkGamePayment?game_id=${game_id}`);

            const response = await request.json();

            checkingPayment = false;

            if (response.feedback == "paid") {
                setIsLoading(false)
                localStorage.setItem(`${game_id}`, true);
                dispatch(setLudusAmount(""));
                dispatch(setChoice1(""));
                dispatch(setChoice2(""));
                navigate({
                    pathname: "/PlayingDICE",
                    search: `?${createSearchParams({ choice: `[${choice1},${choice2}]`, game_id: game_id, started: false })}`,
                });
                return;
            }

        }

        setTimeout(() => { checkPayment(game_id) }, 2000)

    }

    return (
        <div className="container">

            <ToastContainer />

            <div className="row">
                <div className="col">
                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        <Card.Body>
                            <h4>Play Roll a Dice</h4>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col">

                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        <Card.Body>
                            <h4>How to play</h4>

                            <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                                <Card.Body>
                                    <img src={dice} style={{ height: 200, borderRadius: 10, clear: "both" }} />
                                    <p></p>
                                    <p>
                                        Select amount of ludus tokens to stake.
                                    </p>
                                    <p>
                                        Choose a number between 1 and 6 for choice 1 and choice 2
                                    </p>
                                    <p>
                                        Click on continue to play.
                                    </p>
                                </Card.Body>
                            </Card>
                            {isLoading &&
                                <center>
                                    <div>
                                        <div className="loadingio-spinner-eclipse-46i7dk0kdvt">
                                            <div className="ldio-wag2vezkqai">
                                                <div></div>
                                            </div>
                                        </div>
                                        <br />
                                        <span>Loading, please wait....</span>
                                    </div>
                                </center>
                            }
                            <p></p>

                            {!isLoading &&
                                <>
                                    <Row style={{ marginBottom: 23 }}>
                                        <Col>
                                            <Form.Label column sm={2}>
                                                Bet Amount <span style={{ fontSize: "10px" }}>(LUDUS)</span>
                                            </Form.Label>
                                            <Col sm={2}>
                                                <Form.Control type="number" placeholder="Amount" onChange={(e) => {
                                                    dispatch(setLudusAmount(e.target.value));
                                                }}
                                                    value={ludusAmount}
                                                />
                                            </Col>
                                        </Col>
                                        <Col>
                                            <Form.Label column sm={2}>
                                                Choice 1
                                            </Form.Label>
                                            <Col sm={2} style={{ marginTop: 23 }}>
                                                <Form.Select onChange={(e) => {
                                                    dispatch(setChoice1(e.target.value));
                                                }}
                                                    value={choice1}
                                                >
                                                    <option>Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </Form.Select>
                                            </Col>
                                        </Col>
                                        <Col>
                                            <Form.Label column sm={2}>
                                                Choice 2
                                            </Form.Label>
                                            <Col sm={2} style={{ marginTop: 23 }}>
                                                <Form.Select onChange={(e) => {
                                                    dispatch(setChoice2(e.target.value));
                                                }}
                                                    value={choice2}
                                                >
                                                    <option>Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </Form.Select>
                                            </Col>
                                        </Col>
                                    </Row>

                                </>
                            }

                        </Card.Body>
                    </Card>

                </div>
            </div>

        </div>
    )
}

export default PlayDICE;