import { createSlice } from '@reduxjs/toolkit';

export const diceSlice = createSlice({
  name: 'dice',
  initialState: {
    ludusAmount: null,
    choice1: null,
    choice2: null,
  },
  reducers: {
    setLudusAmount: (state, action) => {
      state.ludusAmount = action.payload;
    },
    setChoice1: (state, action) => {
      state.choice1 = action.payload;
    },
    setChoice2: (state, action) => {
      state.choice2 = action.payload;
    },
    resetDice: (state) => {
      state.ludusAmount = null;
      state.choice1 = null;
      state.choice2 = null;
    },
  },
});

export const resetGame = () => (dispatch) => {
  dispatch(setLudusAmount(""));
  dispatch(setChoice1(""));
  dispatch(setChoice2(""));
};

export const { setLudusAmount, setChoice1, setChoice2, resetDice } = diceSlice.actions;

export const selectLudusAmount = (state) => state.dice.ludusAmount;
export const selectChoice1 = (state) => state.dice.choice1;
export const selectChoice2 = (state) => state.dice.choice2;

export default diceSlice.reducer;
